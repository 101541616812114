import { render, staticRenderFns } from "./FavoriteItem.vue?vue&type=template&id=096ee250&scoped=true&"
import script from "./FavoriteItem.vue?vue&type=script&lang=js&"
export * from "./FavoriteItem.vue?vue&type=script&lang=js&"
import style0 from "./FavoriteItem.vue?vue&type=style&index=0&id=096ee250&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "096ee250",
  null
  
)

export default component.exports