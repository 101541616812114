<template>
  <div class="favorite w-full md:w-1/3 px-4 mb-10" v-if="item" :key="item._uid">
    <div class="favorite__item">

      <div class="favorite__media">
        <picture class="favorite__picture">
          <source :srcset="item.image.filename" media="(min-width: 768px)" />
          <img :srcset="item.image.filename" :src="item.image.filename" :alt="item.image.alt" />
        </picture>

        <div class="favorite__buttons favorite__buttons--desktop" v-if="item.btn_1_url.url || item.btn_2_url.url">
          <a v-if="item.btn_1_url.url" :href="item.btn_1_url.url" target="_blank" class="favorite__button">{{ item.btn_1_label }}</a>
          <a v-if="item.btn_2_url.url" :href="item.btn_2_url.url" target="_blank" class="favorite__button">{{ item.btn_2_label }}</a>
        </div>
      </div>

      <h3 class="favorite__item-heading" v-if="item.heading">{{ item.heading }}</h3>
      <p role="doc-subtitle" class="favorite__item-subheading" v-if="item.subheading" v-html="getSubheading"/>

      <div class="favorite__buttons favorite__buttons--mobile" v-if="item.btn_1_url.url || item.btn_2_url.url">
        <a v-if="item.btn_1_url.url" :href="item.btn_1_url.url" target="_blank" class="favorite__button">{{ item.btn_1_label }}</a>
        <a v-if="item.btn_2_url.url" :href="item.btn_2_url.url" target="_blank" class="favorite__button">{{ item.btn_2_label }}</a>
      </div>
    </div>
  </div>

</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'FavoriteItem',
  extends: Blok,
  methods: {
    getRichtext (data) {

      if (!data) return ''
      if (!data.content) return ''
      if (!data.content.length) return ''
      console.log('data', data)
      return this.$storyblokClient.richTextResolver.render(data)
    }
  },
  computed: {
    getSubheading () {
      return this.getRichtext(this.item.subheading)
    }
  }
}
</script>

<style scoped lang="scss">
// Media queries
$iphone6: 375px;
$iphone6-plus: 414px;
$iphone4-landscape: 480px;
$small-device: 576px;
$iphone6-landscape: 667px;
$iphone6-plus-landscape: 736px;
$tablet: 768px;
$tablet-medium: 800px;
$tablet-landscape: 1024px;
$not-tablet: $tablet-landscape + 1;
$desktop: 1200px;
$desktop-large: 1400px;
$desktop-x-large: 1600px;
$site-max: 1440px;

$black: #000;
$white: #fff;

// Breakpoint Range
@mixin bp-range($min, $max) {
  @media (min-width: $min) and (max-width: $max - 1) {
    @content;
  }
}

// Breakpoint Max
@mixin bp-max(
  $breakpoint,
  $media-query-type: "max-width",
  $media-query: "#{$media-query-type}: #{$breakpoint - 1}"
) {
  @media (#{$media-query}) {
    @content;
  }
}

// Breakpoint Min
@mixin bp(
  $breakpoint,
  $media-query-type: "min-width",
  $media-query: "#{$media-query-type}: #{$breakpoint}"
) {
  @media (#{$media-query}) {
    @content;
  }
}

@mixin small {
  @include bp($small-device) {
    @content;
  }
}

@mixin tablet {
  @include bp($tablet) {
    @content;
  }
}

@mixin tablet-max {
  @include bp-max($tablet) {
    @content;
  }
}

@mixin tablet-medium {
  @include bp($tablet-medium) {
    @content;
  }
}

@mixin tablet-landscape {
  @include bp($tablet-landscape) {
    @content;
  }
}

@mixin tablet-landscape-max {
  @include bp-max($tablet-landscape) {
    @content;
  }
}

@mixin not-tablet {
  @include bp($not-tablet) {
    @content;
  }
}

@mixin desktop {
  @include bp($desktop) {
    @content;
  }
}

@mixin desktop-max {
  @include bp-max($desktop) {
    @content;
  }
}

@mixin desktop-large {
  @include bp($desktop-large) {
    @content;
  }
}

@mixin desktop-x-large {
  @include bp($desktop-x-large) {
    @content;
  }
}

@mixin max {
  @include bp($site-max) {
    @content;
  }
}

@mixin padding-sides($mobile-padding: 26px, $tablet-landscape-padding: 40px, $desktop-padding: 55px) {
  padding-left: $mobile-padding;
  padding-right: $mobile-padding;

  @include tablet-landscape {
    padding-left: $tablet-landscape-padding;
    padding-right: $tablet-landscape-padding;
  }

  @include desktop {
    padding-left: $desktop-padding;
    padding-right: $desktop-padding;
  }
}

@mixin button(
  $border: null,
  $border-width: 1px,
  $color: $white,
  $desktop-styles: true,
  $font-size: 12px,
  $font-size-desktop: 17px,
  $height: "standard",
  $hover: true,
  $letter-spacing: 0.1em,
  $min-width: 120px,
  $min-width-desktop: 150px,
  $padding: null,
  $padding-desktop: null,
  $theme: "dark",
  $width: auto,
  $width-desktop: null
) {
  @if ($theme == "light") {
    $color: '#000';
  }
  @if ($theme == "outline") {
    $letter-spacing: 0.2em;
    $font-size: 18px;
    $padding: 20px;
    $width: 100%;
    $border: 1px solid #707070;
    background: transparent;
  }
  @if ($height == 'tall') {
    $font-size: 18px;
    $letter-spacing: 0.2em;
    $min-width-desktop: 275px;
    $padding: 21px 20px 20px;
    $padding-desktop: 21px 48px 20px;
    $width: 100%;
    $width-desktop: auto;
  }
  border: $border;
  border-radius: 0;
  display: inline-block;
  font-size: $font-size;
  letter-spacing: $letter-spacing;
  line-height: 17px;
  max-width: 100%;
  min-width: $min-width;
  overflow: visible; // IE
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 250ms, border-color 250ms, color 250ms;
  white-space: nowrap;
  width: $width;

  @if $padding {
    padding: $padding;
  } @else {
    @if $height == "standard" {
      padding: 12px 20px 9px;
    }
  }

  @if $theme == "dark" {
    background: #000;
    border: $border-width solid '#000';
    color: $color;
  } @else if $theme == "light" {
    background: transparent;
    @if ($border == null) {
      border: $border-width solid $white;
    } @else {
      border: $border;
    }
    color: $color;
  }

  @if $hover == true {
    .no-touchevents & {
      &:hover {
        @if $theme == "dark" {
          background: transparent;
          border-color: $black;
          color: $black;
        } @else if $theme == "light" or $theme == "outline" {
          background: $black;
          border-color: $black;
          color: $white;
        }
        text-decoration: none;
      }
    }
  }

  @if $desktop-styles {
    @include desktop {
      font-size: $font-size-desktop;
      min-width: $min-width-desktop;
      width: $width-desktop;

      @if $padding-desktop {
        padding: $padding-desktop;
      } @else {
        @if $height == "standard" {
          padding: 19px 25px 18px;
        }
      }
    }
  }

  &:disabled {
    color: #000 !important;
    background-color: #e6e6e6 !important;
    border-color: #e6e6e6 !important;
    pointer-events: none;
  }
  @content;
}

@mixin fill-container {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

$ns: '.favorite';

#{$ns} {
  &__title {
    @include padding-sides;
    margin-bottom: 28px;

    @include tablet {
      margin-bottom: 50px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    @include padding-sides;

    &::-webkit-scrollbar {
      display: none;
    }

    @include tablet {
      max-width: $desktop-x-large;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__item {
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    @include tablet {
      margin-right: 25px;
      flex: 1 1 calc(100% / 3);
    }

    @include tablet-landscape {
      margin-right: 50px;
    }
  }

  &__media {
    position: relative;
    margin: 0 0 25px;

    @include tablet {
      margin: 0 0 32px;
    }
  }

  &__media:hover {
    @include tablet {
      #{$ns}__buttons--desktop {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__item-heading {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.1em;
    margin-bottom: 12px;
    text-transform: uppercase;

    @include tablet-landscape {
      font-size: 28px;
      line-height: 32px;
    }
  }

  &__item-subheading {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 275px;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
      max-width: none;
    }
  }

  &__buttons--mobile {
    margin-top: auto;

    @include tablet {
      display: none;
    }
  }

  &__buttons--desktop {
    display: none;
    aligns: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    @include fill-container;
    background: rgba(0,0,0,0.5);
    transition: all 250ms;

    @include tablet {
      display: flex;
    }
  }

  &__button {
    @include button (
      $theme: "light",
      $letter-spacing: 0.2em,
      $font-size: 18px,
      $padding: 20px,
      $width: 100%,
      $border: 1px solid #707070
    );
    background-color: #fff;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    @include tablet {
      max-width: 275px;
      margin-left: auto;
      margin-right: auto;
    }

    @include bp-range($tablet, $tablet-landscape) {
      font-size: 16px;
      padding: 15px;
      width: 90%;
    }
  }
}
</style>
